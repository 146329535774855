import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link } from "react-router-dom";
import orangeLogo from "@/assets/img/orange_logo.png";

export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-center mb-8">
        <img src={orangeLogo} alt="Company Logo" />
      </div>
      <Card>
        <CardHeader>
          <CardTitle className="text-3xl font-bold">
            JumpBall Privacy Policy
          </CardTitle>
          <CardDescription>
            Last updated: {new Date().toLocaleDateString()}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <nav className="mb-8">
            <h2 className="text-xl font-semibold mb-2">Table of Contents</h2>
            <ul className="list-disc pl-5 space-y-1">
              <li>
                <Link
                  to="#information-collection"
                  className="text-black hover:underline"
                >
                  Information Collection and Use
                </Link>
              </li>
              <li>
                <Link to="#data-sharing" className="text-black hover:underline">
                  Data Sharing and Disclosure
                </Link>
              </li>
              <li>
                <Link
                  to="#data-security"
                  className="text-black hover:underline"
                >
                  Data Security
                </Link>
              </li>
              <li>
                <Link to="#user-rights" className="text-black hover:underline">
                  Your Rights and Choices
                </Link>
              </li>
              <li>
                <Link
                  to="#policy-changes"
                  className="text-black hover:underline"
                >
                  Changes to This Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="#contact" className="text-black hover:underline">
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>

          <section id="information-collection" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Information Collection and Use
            </h2>
            <p className="mb-4">
              We collect several different types of information for various
              purposes to provide and improve our JumpBall mobile app and
              website (collectively, the "Service") to you.
            </p>
            <h3 className="text-xl font-semibold mb-2">
              Types of Data Collected
            </h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <strong>Personal Data:</strong> While using our Service, we may
                ask you to provide us with certain personally identifiable
                information that can be used to contact or identify you. This
                may include, but is not limited to, your email address, name,
                and profile picture.
              </li>
              <li>
                <strong>Usage Data:</strong> We may also collect information on
                how the Service is accessed and used, both on the mobile app and
                the website.
              </li>
              <li>
                <strong>Camera Access:</strong> Our mobile app may request
                access to your device's camera. This is solely for the purpose
                of allowing you to add images to your profile or other sections
                of the app. Please note that providing camera access is optional
                and not required to use the core features of JumpBall.
              </li>
            </ul>
          </section>

          <section id="data-sharing" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Data Sharing and Disclosure
            </h2>
            <p className="mb-4">
              We may disclose your Personal Data in the good faith belief that
              such action is necessary to:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of JumpBall</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
            </ul>
          </section>

          <section id="data-security" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
            <p className="mb-4">
              The security of your data is important to us, but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security. We implement appropriate
              technical and organizational measures to protect the security of
              your personal information both on our mobile app and website.
            </p>
          </section>

          <section id="user-rights" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Your Rights and Choices
            </h2>
            <p className="mb-4">
              You have certain rights relating to your personal data, subject to
              local data protection laws. These may include the right to:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Access your personal data</li>
              <li>Rectify the information we hold about you</li>
              <li>Erase your personal data</li>
              <li>Restrict our use of your personal data</li>
              <li>Object to our use of your personal data</li>
              <li>
                Receive your personal data in a usable electronic format and
                transmit it to a third party (right to data portability)
              </li>
              <li>
                Withdraw your consent at any time for data processing based on
                consent
              </li>
            </ul>
            <p className="mt-4">
              You can exercise many of these rights through your account
              settings in the JumpBall app or website. For any additional
              requests, please contact us using the information provided in the
              "Contact Us" section.
            </p>
          </section>

          <section id="policy-changes" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Changes to This Privacy Policy
            </h2>
            <p className="mb-4">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the "Last updated" date at the top of this Privacy
              Policy. You are advised to review this Privacy Policy periodically
              for any changes. Changes to this Privacy Policy are effective when
              they are posted on this page.
            </p>
          </section>

          <section id="contact" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="mb-4">
              If you have any questions about this Privacy Policy or our data
              practices, please contact us:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>By email: contact@jumpballapp.com</li>
              <li>
                By visiting our website:{" "}
                <Link
                  to="https://jumpballapp.com"
                  className="text-black hover:underline"
                >
                  jumpballapp.com
                </Link>
              </li>
            </ul>
          </section>
        </CardContent>
      </Card>
    </div>
  );
}
