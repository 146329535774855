import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          "Connect, Play, and Score with JumpBall App":
            "Connect, Play, and Score with JumpBall App",
          "Organize basketball games and meet new players in your area. Your next great game is just a tap away!":
            "Organize basketball games and meet new players in your area. Your next great game is just a tap away!",
          Features: "Features",
          "Key Features": "Key Features",
          "How It Works": "How it works",
          "Try It Now!": "Try It Now!",
          "Find Players": "Find Players",
          "Connect with basketball enthusiasts in your area.":
            "Connect with basketball enthusiasts in your area.",
          "Locate Courts": "Locate Courts",
          "Discover nearby basketball courts for your games.":
            "Discover nearby basketball courts for your games.",
          "Schedule Games": "Schedule Games",
          "Easily organize and join basketball games.":
            "Easily organize and join basketball games.",
          "Create Your Profile": "Create Your Profile",
          "Sign up and set your preferences for playing basketball.":
            "Sign up and set your preferences for playing basketball.",
          "Find or Create a Game": "Find or Create a Game",
          "Browse available games or start your own match.":
            "Browse available games or start your own match.",
          "Play and Connect": "Play and Connect",
          "Meet up, play basketball, and make new friends!":
            "Meet up, play basketball, and make new friends!",
          "Try JumpBall Today": "Try JumpBall Today",
          "Get ready to revolutionize your basketball experience. Download the app now!":
            "Get ready to revolutionize your basketball experience. Download the app now!",
          "Try Online": "Try Online",
          "Learn More": "Learn More",
        },
      },
      es: {
        translation: {
          // here we will place our translations...
          "Connect, Play, and Score with JumpBall App":
            "Conecta, Juega y Diviértete con JumpBall App",
          "Organize basketball games and meet new players in your area. Your next great game is just a tap away!":
            "Organiza partidas de baloncesto y conoce nuevos jugadores en tu ciudad. Tu próxima gran partida es solo un toque!",
          Features: "Funciones",
          "Key Features": "Funciones principales",
          "How It Works": "¿Cómo funciona?",
          "Try It Now!": "¡Pruébala Ahora!",
          "Find Players": "Encuentra jugadores",
          "Connect with basketball enthusiasts in your area.":
            "Conecta con amantes del baloncesto en tu ciudad.",
          "Locate Courts": "Encuentra Pistas",
          "Discover nearby basketball courts for your games.":
            "Descubre pistas de baloncesto cercanas para jugar.",
          "Schedule Games": "Organiza Partidos",
          "Easily organize and join basketball games.":
            "Organiza o únete a partidas de baloncesto.",
          "Create Your Profile": "Crea tu Perfil",
          "Sign up and set your preferences for playing basketball.":
            "Regístrate y configura tus preferencias para jugar baloncesto.",
          "Find or Create a Game": "Encuentra o Crea una Partida",
          "Browse available games or start your own match.":
            "Busca partidas disponibles o crea la tuya.",
          "Play and Connect": "Juega y Conecta",
          "Meet up, play basketball, and make new friends!":
            "Juega baloncesto y has nuevos amigos!",
          "Try JumpBall Today": "Prueba JumpBall Hoy Mismo",
          "Get ready to revolutionize your basketball experience. Download the app now!":
            "Estás listo para revolucionar tu experiencia de baloncesto. ¡Descarga la aplicación ahora!",
          "Try Online": "Prueba la versión Online",
          "Learn More": "Descubre más",
        },
      },
    },
  });

export default i18n;
